import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   *Creates an instance of AuthService.
   * @memberof AuthService
   */
  constructor(private http: HttpClient) { }


  /**
   * Check if user is authenticated.
   *
   * @returns {boolean}
   * @memberof AuthService
   */
  public isAuthenticated(): boolean {
    // get token from local storage
    let token = localStorage.getItem('auth');
    // Check whether the token is expired and return
    // true or false
    if (token) {
      let data = JSON.parse(token);
      if (this.isAccessTokenActive() === false) {
        localStorage.removeItem('auth');
      } else {
        return true;
      }
    }

    return false;
  }


  /**
   * Check if access token is active.
   *
   * @returns
   * @memberof AuthService
   */
  isAccessTokenActive(): boolean {
    // get token from local storage
    let token = localStorage.getItem('auth');
    // Check whether the token is expired and return
    // true or false
    if (token) {
      let data = JSON.parse(token);
      if (Math.floor(new Date().getTime() / 1000) > data.expires_at) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }


  /**
   * Get the acces token from local storage.
   *
   * @returns {(string|null)}
   * @memberof AuthService
   */
  getAccessToken(): string | null {
    let auth = localStorage.getItem('auth');
    if (auth) {
      let data = JSON.parse(auth);
      return data.access_token;
    }

    return null;
  }


  /**
   * Get the refresh token from local storage.
   *
   * @returns {(string|null)}
   * @memberof AuthService
   */
  getRefreshToken(): string | null {
    let auth = localStorage.getItem('auth');
    if (auth) {
      let data = JSON.parse(auth);
      return data.refresh_token;
    }

    return null;
  }

  getAuth() {
    let auth = localStorage.getItem('auth');
    if (auth) {
      let data = JSON.parse(auth);
      return data;
    }

    return null;
  }

  /**
   * Clear the auth data from storage
   *
   * @memberof AuthService
   */
  clearAuthData() {
    localStorage.removeItem('auth');
    localStorage.clear();
  }


  /**
  * Login.
  *
  * @param {*} params
  * @returns {Observable<any>}
  * @memberof LoginService
  */
  login(params): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/login`, params);
  }

  refreshToken() {
    return this.http.post(`${environment.apiUrl}/auth/refresh`, {
      refresh_token: this.getRefreshToken()
    });
  }

  /**
   * Logout service.
   *
   * @returns
   * @memberof AuthService
   */
  logout() {
    // Get the access
    let accessToken = this.getAccessToken();
    // Clear the auth data
    this.clearAuthData();

    return this.http.post(`${environment.apiUrl}/auth/logout`, {
      access_token: accessToken
    });
  }

  register(data) {
    return this.http.post(`${environment.apiUrl}/auth/register`, data);
  }

  rememberPassword(data) {
    return this.http.post(`${environment.apiUrl}/auth/remember-password`, data);
  }

  restorePassword(data) {
    return this.http.post(`${environment.apiUrl}/auth/restore-password`, data);
  }

  checkEmailUniqueness(emailAddress) {
    return this.http.get(`${environment.apiUrl}/auth/check-email?email=${emailAddress}`);
  }

  checkRecoverHash(hash) {
    return this.http.get(`${environment.apiUrl}/auth/check-recover-hash?hash=${hash}`);
  }
}
