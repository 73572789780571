import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatButtonModule, MatProgressSpinnerModule } from '@angular/material';
import { CoreModule } from './core/core.module';
import {DatePipe} from '@angular/common'
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    CoreModule,
    MatProgressSpinnerModule,
    MatButtonModule
  ],
  entryComponents: [

  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
